.transform90 {
    -webkit-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
    -ms-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    transform:rotate(90deg);
}

.main-card {
    margin: auto;
    line-height: 1.5rem; 
    width: 160mm;
    border: none;
    font-size: 1.2rem;
}