@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Averia+Libre&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bowlby+One+SC&display=swap);
.transform90 {
    -webkit-transform:rotate(90deg);
    transform:rotate(90deg);
}

.main-card {
    margin: auto;
    line-height: 1.5rem; 
    width: 160mm;
    border: none;
    font-size: 1.2rem;
}
:root {
  --mainColor: #ffffff;
  --classyGreen: #06a575;
  --classyRed: #da1e37; /*  #e4063e */
  --footerColor: #203a43;
  --gradient: -webkit-linear-gradient(45deg, #0f2027, #203a43, #2c5364);
  --gradientSmall: -webkit-linear-gradient(90deg, #0f2027, #203a43, #2c5364);
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  text-decoration: none;
  box-sizing: border-box;
}

html {
  width: 100vw;
  font-size: 65%;
  scroll-behavior: smooth;
}

body {
  background-color: #ffffff;
  background-color: var(--mainColor);
  box-sizing: border-box;
  font-family: poppins;
  color: rgb(47, 65, 71);
  font-size: 1.6rem;
  line-height: 1.8;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

a, a:hover {
  color: inherit;
}

p {
  margin: 0;
}

.scroll-top {
  /* display: none; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 2.5em;
  height: 2.5em;
  text-align: center;
  font-size: 1.5rem;
  border-radius: 50%;
  outline: none;
  border: none;
  background-color: #da1e37;
  background-color: var(--classyRed);
  z-index: 5000;
  transition: 0.5s ease-in-out;
}

.scroll-top svg path {
  stroke: whitesmoke;
}

.scroll-top:hover {
  opacity: 0.9;
}

.scroll-top:active {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 5px 5px 2px rgba(130, 167, 179, 0.568);
}

main {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-color: var(--mainColor);
}

section {
  transition: smooth;
}

/* section:nth-child(even) {
  background-color: rgb(250, 252, 252);
} */

.topic {
  position: relative;
  display: flex;
  align-items: center;
}

.topic-icon {
  position: absolute;
  size: 2em;
  left: -3rem;
}

/********************INTRO*************/

#intro {
  width: 100%;
  /* height: 98vh; */
  background-color: #ffffff;
  background-color: var(--mainColor);
  position: relative;
  box-shadow: 0px 2px 2px 0px rgb(0 0 0 / 40%);
  z-index: 1000;
}

.intro-main {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  background-color: var(--mainColor);
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 5px 2px rgba(211, 206, 206, 0.336);
}

.intro-div-box {
  width: 100%;
  height: min(80vh, 900px);
  display: flex;
  justify-content: center;
  background-color: rgb(1, 12, 10);
  background-image: -webkit-linear-gradient(45deg, #0f2027, #203a43, #2c5364);
  background-image: var(--gradient);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
  overflow: hidden;
  clip-path: polygon(0% 0%, 100% 0, 100% 84%, 35% 100%, 0 79%);
  -webkit-clip-path: polygon(0% 0%, 100% 0, 100% 84%, 35% 100%, 0 79%);
  -moz-clip-path: polygon(0% 0%, 100% 0, 100% 84%, 35% 100%, 0 79%);
}

.intro-div {
  position: relative;
  width: min(1400px,100%);
  height: 100%;
}

.shape-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 5;
}

.shape-bottom svg {
  position: relative;
  display: block;
  width: calc(121% + 1.3px);
  height: 150px;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

.shape-bottom .shape-fill {
  fill: #ffffff;
  fill: var(--mainColor);
}

.intro-main .content-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 0;
  padding: 20px 10%;
}

.illustration {
  position: absolute;
  height: 80%;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #203a43;
  /* background-image: url("../images/illu.png"); */
  background-blend-mode: overlay;
  background-size: contain;
  background-repeat: no-repeat;
  /* clip-path: polygon(0 0, 100% 0, 100% 85%, 20% 85%); */
  opacity: 0.05;
}

.illus-div {
  float: right;
  width: 70%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.illus {
  width: 100%;
  max-width: 600px;
  /* box-shadow: 0 00px 10px 10px #43585e8f; */
}

.illus-watch {
  display: none;
}

.title-wrap {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 10;
  display: grid;
  grid-template-columns: 60% 1fr;
  justify-items: center;
}

.desc {
  padding: 10px;
  display: grid;
  /* align-items: center; */
  justify-content: center;
  font-size: 2rem;
  color: #d9dbdb;
  letter-spacing: 1px;
}

.contain-wrap {
  width: 100%;
  max-width: 400px;
  padding-left: 5%;
}

.title-theme {
  width: 100%;
  min-height: 17em;
  display: grid;
  grid-template-columns: minmax(1fr, 300px);
  justify-content: center;
  align-content: space-between;
  position: relative;
}

.title {
  cursor: pointer;
  margin-top: 5%;
}

@-webkit-keyframes line-transit {
  from {
    left: -80%;
  }
  to {
    left: -10%;
  }
}

@keyframes line-transit {
  from {
    left: -80%;
  }
  to {
    left: -10%;
  }
}
@-webkit-keyframes line-transit-1 {
  from {
    left: -80%;
  }
  to {
    left: 0;
  }
}
@keyframes line-transit-1 {
  from {
    left: -80%;
  }
  to {
    left: 0;
  }
}

.title-theme::before {
  content: "";
  position: absolute;
  width: 70%;
  height: 10px;
  background-color: #da1e37;
  background-color: var(--classyRed);
  border-radius: 10px;
  top: 55%;
  left: -10%;
  z-index: 10;
  transition: 1s ease-in-out;
  -webkit-animation-name: line-transit;
          animation-name: line-transit;
  -webkit-animation-duration: 1.2s;
          animation-duration: 1.2s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.title-theme::after {
  content: "";
  position: absolute;
  width: 70%;
  height: 10px;
  background-color: #da1e37;
  background-color: var(--classyRed);
  border-radius: 10px;
  top: 59.5%;
  transition: 0.5s ease-in-out;
  z-index: 10;
  -webkit-animation-name: line-transit-1;
          animation-name: line-transit-1;
  -webkit-animation-duration: 0.8s;
          animation-duration: 0.8s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

.title h1 {
  font-size: 3.8em;
  color: rgb(255, 255, 255);
  line-height: 0.9em;
}

.title-theme h5,
.title-theme p {
  font-size: 16px;
  padding-left: 5px;
  font-weight: unset;
}

.theme {
  display: grid;
  grid-gap: 3rem;
}

.resume-socLink {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.resume_link {
  min-width: 100px;
  background: #da1e37;
  background: var(--classyRed);
  font-size: 1.3rem;
  padding: 3%;
  border-radius: 12px;
  color: #ffffff;
  color: var(--mainColor);
  text-decoration: none;
}

.resume_link:active,
.resume_link:hover {
  opacity: 0.8;
}

.top-social {
  margin-left: 3%;
  max-width: 100px;
  top: 50%;
  right: 40%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: center;
}

.top-social a {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.top-social a:hover {
  opacity: 1;
}

.top-social a:last-child img {
  background: rgb(197, 204, 216);
  border-radius: 50%;
}

.top-social img {
  width: 80%;
  height: 80%;
}

.main_img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
}

.main_img img {
  width: 380px;
  max-height: 400px;
  object-fit: cover;
  border-radius: 10px;
  z-index: 1;
  box-shadow: 100px 50px 10px 10px rgba(0, 0, 0, 0.336),
              0 0 5px 0px rgba(0, 0, 0, 0.336);
}

@media only screen and (max-width: 960px) {
  .illus-div {
    width: 60%;
  }
}

@media only screen and (max-width: 700px) {
  .illus-div {
    width: 50%;
  }
  .illus-coding {
    display: none;
  }
  .illus-watch {
    display: block;
  }

  .desc {
    margin-left: 10%;
  }
}

.moon-div {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 10%;
  top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s ease-in-out;
  z-index: 4;
}

.moon {
  width: 60%;
  height: 60%;
  padding: 20px;
  float: right;
  background-color: rgb(230, 227, 227);
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 2px 1px rgba(168, 167, 167, 0.663),
    inset 0 0 20px 25px rgba(255, 255, 255, 0.8),
    0 0 100px 50px rgba(255, 255, 255, 0.219),
    0 0 100px 120px rgba(255, 255, 255, 0.219);
  z-index: 5;
  position: relative;
}

.moon::before {
  content: "";
  position: absolute;
  width: 12%;
  height: 12%;
  border-radius: 70% 45% 60% 40%;
  background: rgba(189, 185, 185, 0.308);
}
.moon::after {
  content: "";
  position: absolute;
  width: 20%;
  height: 20%;
  left: 35px;
  top: 35px;
  border-radius: 50% 38% 60% 40%;
  background: rgba(207, 203, 203, 0.418);
}

@-webkit-keyframes cloud-move3 {
  from {
    right: -100%;
  }
  to {
    right: 40;
  }
}

@keyframes cloud-move3 {
  from {
    right: -100%;
  }
  to {
    right: 40;
  }
}
@-webkit-keyframes cloud-move2 {
  from {
    right: -100%;
  }
  to {
    right: -10%;
  }
}
@keyframes cloud-move2 {
  from {
    right: -100%;
  }
  to {
    right: -10%;
  }
}

.cloud {
  position: absolute;
  width: 80%;
  height: 35%;
  background-color: #cccccc;
  /* background-image: linear-gradient(to top, #ababab, #cccccc, #f6f6f6); */
  border-radius: 50px 40px 30px 30px;
  box-shadow: 0 0 10px 2px rgba(92, 89, 89, 0.705),
    inset 0 0 10px 2px rgba(92, 89, 89, 0.705);
  transition: 0.5s ease-in-out;
}

.cloud::before {
  content: "";
  position: absolute;
  background-color: #cccccc;
  width: 50%;
  height: 100%;
  border-radius: 50%;
  top: -12px;
  left: 8px;
}

.cloud::after {
  content: "";
  position: absolute;
  background-color: #cccccc;
  width: 60%;
  height: 100%;
  border-radius: 50%;
  top: -18px;
  left: 30px;
}

.cloud1 {
  top: 70px;
}

.cloud2 {
  top: 80px;
  right: -10%;
  z-index: 5;
  -webkit-animation: cloud-move2 1.2s 1s;
          animation: cloud-move2 1.2s 1s;
}

.cloud3 {
  top: 90px;
  right: 40%;
  z-index: 6;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
  -webkit-animation: cloud-move3 2s 1s;
          animation: cloud-move3 2s 1s;
}

/* #cloud-circle {
  width: 500px;
  height: 275px;
  border-radius: 50%;
  filter: url(#filter);
  box-shadow: 400px 400px 60px 0px #fff;
  position: absolute;
  top: -320px;
  left: -320px;
} */

/*********************INTRO_END***************/

/*****************HEADER**************************/

header {
  grid-area: second;
  width: 100%;
  height: 4em;
  background-color: #ffffff;
  background-color: var(--mainColor);
  /* box-shadow: 0px 2px 2px 0px rgb(0, 0, 0, 0.4); */
  z-index: 1000;
}

.header {
  width: 100%;
  height: 100%;
  transition: ease-in-out 0.5s;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.box {
  width: min(1400px, 90%);
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  transition: ease-in-out 0.5s;
}

.stick {
  position: fixed;
  top: 0;
  background-color: rgba(153, 228, 238, 0.274);
  box-shadow: none;
  box-shadow: 0 0px 10px 0px rgba(13, 13, 14, 0.274);
  -webkit-backdrop-filter: blur(0.3rem);
          backdrop-filter: blur(0.3rem);
}

.logo_cont {
  padding: 4px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.logo {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prof_img {
  width: 100px;
  height: 50px;
  object-fit: cover;
  transition: 0.8s ease all;
}

.prof_img:hover {
  opacity: 0.8;
}

.stick .prof_img {
  width: 80px;
}

nav {
  padding: 0 10px;
  display: grid;
  /* grid-template-columns: 1fr 100px; */
}

.nav-bar {
  padding: 0 10px;
  flex: 9 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.nav-bar li {
  list-style: none;
}

.nav-bar li a {
  font-size: 14px;
  padding: 4px;
  font-family: sans-serif;
  font-weight: 550;
  color: #515455;
  transition: 0.2s ease-in-out;
}

.nav-bar li a:active,
.nav-bar li a:hover {
  text-shadow: 0px 0px 30px #021811;
}

.nav-bar .active-link {
  border-bottom: 2px solid #da1e37;
  border-bottom: 2px solid var(--classyRed);
}

.soc_media {
  width: 100%;
  height: 100%;
  flex: 2 1;
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
  justify-items: center;
  align-items: center;
}

.soc_media a {
  display: grid;
  justify-items: center;
  align-items: center;
}

.soc_media a img {
  width: 20px;
  height: 20px;
  transition: 0.5s ease;
}

.soc_media a img:hover {
  opacity: 0.6;
}

#header .lines {
  height: 100%;
  justify-self: flex-end;
  display: none;
}

.line-div {
  width: 100%;
  padding: 5px 0;
  display: grid;
  grid-gap: 3px;
  justify-items: center;
  align-items: center;
  transition: 0.2s ease;
  cursor: pointer;
}

.line-div:active {
  background-color: rgb(6, 24, 24);
}

.line {
  width: 25px;
  height: 2.5px;
  background-color: black;
  transition: -webkit-transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  -moz-transition: transform 0.3s ease-in;
  -o-transition: transform 0.3s ease-in;
  -webkit-transition: transform 0.3s ease-in;
}

.cross .l2 {
  display: none;
}

.cross .l1 {
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
  -webkit-transform-origin: 8px;
          transform-origin: 8px;
}
.cross .l3 {
  -webkit-transform: rotateZ(-45deg);
          transform: rotateZ(-45deg);
  -webkit-transform-origin: 8px;
          transform-origin: 8px;
}

/*****************HEADER-END****************/

#about,
#skill,
#gallery {
  width: 100%;
  /* min-height: 100vh; */
}

.padTop {
  padding-top: 4em;
}

#contact {
  width: 100%;
  /* min-height: 70vh; */
  background-color: #203a43;
  background-color: var(--footerColor);
  background-image: -webkit-linear-gradient(45deg, #0f2027, #203a43, #2c5364);
  background-image: var(--gradient);
}

/**********************main contents***************/

.main-box {
  background-color: #ffffff;
  background-color: var(--mainColor);
  width: 100%;
  padding: 5% 0;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.main-box:before,
.main-box:after {
  content: "";
  position: absolute;
  width: 20rem;
  height: 20rem;
  background: linear-gradient(
    to bottom right,
    rgba(10, 185, 243, 0.9),
    rgba(247, 8, 68, 0.9)
  );
  border-radius: 36% 64% 82% 18% / 84% 67% 33% 16% ;
}

.main-box:before {
  top: 12px;
  right: 15px;
}

.main-box:after {
  bottom: 12px;
  left: 15px;
  z-index: 1;
}

.div-box {
  background-color: rgba(197, 232, 238, 0.24); /* rgb(241, 247, 247) */
  width: min(1400px, 85%);
  height: 100%;
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  -webkit-backdrop-filter: blur(1rem);
  -moz-backdrop-filter: blur(1rem);
  box-shadow: 0 0 10px 2px rgba(3, 56, 56, 0.205),
              0px 62px 91px -56px rgba(11,68,82,1);

  position: relative;
  overflow: hidden;
  z-index: 5;
}

.div-box:before {
  content: "about";
  position: absolute;
  top: 8px;
  left: -38px;
  -webkit-transform-origin: center;
          transform-origin: center;
  transform: rotate(-40deg);
  -webkit-transform: rotate(-40deg);
  -moz-transform: rotate(-40deg);
  color: rgb(255, 255, 255);
  width: 120px;
  text-align: center;
  text-decoration: underline;
  background: #da1e37;
  background: var(--classyRed);
  box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.445);
  font-size: 14px;
}

.work-content .div-box:before {
  content: "work";
}

.project-content .div-box:before {
  content: "project";
}

.skill-content .div-box:before {
  content: "skill";
}

.resume .div-box:before {
  content: "";
  width: 0;
}

/********************About***********************/
.content-wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;
  font-size: 90%;
  overflow-wrap: break-word;
  display: grid;
  grid-gap: 10px;
  grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
}

.about-profile {
  width: 100%;
  padding-bottom: 20;
  border-bottom: 1px solid rgba(0, 0, 0, 0.247);
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  justify-items: center;
}

.image-prof {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-div {
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min(80%, 450px);
  padding-bottom: 50%;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 50px -5px rgba(4,42,54);
  transition: ease-in-out 0.5s;
}

.image-prof img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  cursor: pointer;
  image-rendering: auto;
  z-index: 2;
}

/* .image-prof .img-div:hover {
  transform: translateY(-12px);
  box-shadow: -2px 2px 5px 5px rgba(0, 0, 0, 0.329);
} */

.brief {
  padding: 20px 20px;
  display: grid;
  grid-gap: 5px;
}

.brief h2 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.438);
}

.bio {
  min-height: 250px;
  padding: 2px 5%;
  font-size: 1em;
}

.bio_border {
  margin: 3% 0 2%;
  padding: 20px 3% 2%;
  position: relative;
  text-align: justify;
  border: 1px solid #da1e37;
  border: 1px solid var(--classyRed);
}

.bio_border:before {
  content: "\201C";
  top: -62.5px;
  left: -3px;
  color: #da1e37;
  color: var(--classyRed);
  font-size: 100px;
  font-family: Georgia, "Times New Roman", Times, serif;
  position: absolute;
}

/****************** Work ********************/
.work-experience {
  padding: 1em 2%;
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  justify-content: center;
  align-items: baseline;
}

.work-head {
  width: 100%;
  padding-left: 2.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.438);
}

.work-body {
  display: flex;
  grid-gap: 2em;
  gap: 2em;
  justify-content: center;
  align-items: baseline;
}

.work-title, .work-desc {
  display: flex;
  flex-direction: column;
}

.work-title {
  flex: 1 1;
  padding: 0 1rem;
  align-items: flex-end;
  border-bottom: 3px solid #da1e37;
  border-bottom: 3px solid var(--classyRed);
  border-radius: 0 0 0 50%/10em;
}

.work-title > h2 {
  line-height: 1em;
  text-align: right;
}

.work-desc {
  flex: 2 1;
  align-items: flex-start;
}

@media only screen and (max-width: 480px) {
  .work-body {
    padding: 0 5% 5%;
    flex-direction: column;
    grid-gap: 1em;
    gap: 1em;
  }

  .work-title, .work-desc {
    align-items: flex-start;
  }
  .work-title {
    border-radius: 0 0 50%/10em 0;
  }

  .work-title > h2 {
    text-align: left;
  }
}

/*******************SKILL*********************/

.programming-cont {
  width: 100%;
  padding: 10px 0;
}

.skill-wrap h1 {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #434444;
}

.programming-cont h1 {
  margin-left: 10px;
  margin-bottom: 10px;
}

.programming {
  width: 100%;
  display: grid;
  grid-gap: 20px;
  grid-auto-rows: auto;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}

@media only screen and (min-width: 480px) {
  .programming-cont {
    padding: 10px 20px;
  }

  /* .programming {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  } */
}

.skill-container {
  height: 100%;
  background-color: #ffffff;
  background-color: var(--mainColor);
  box-shadow: 5px 7px 10px 5px rgba(0, 0, 0, 0.3);
  display: grid;
  grid-gap: 5px;
  transition: ease-in-out 0.5s;
  overflow: hidden;
}

.skill-container:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: 4px 15px 15px 8px rgba(0, 0, 0, 0.3);
}

.skill-container h2 {
  text-align: center;
  font-size: 1.3em;
}

.skill-div {
  position: relative;
}

.skill-div:before {
  content: "";
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0);
  box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.3),
    inset 0 0 4px 5px rgba(0, 0, 0, 0.3), 0 4px 4px 6px rgba(0, 0, 0, 0.3);
}

.skill-level {
  position: relative;
  width: 100%;
  max-height: 120px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.textPercent {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
}

.skill-circle-front {
  stroke: #da1e37;
  stroke: var(--classyRed);
  -webkit-transform-origin: center;
          transform-origin: center;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transition: stroke-dashoffset 1s;
}

.skill-circle-back {
  stroke: rgb(255, 255, 255);
  stroke-width: 8;
  box-shadow: 0 0 4px 20px black;
}

/*OTHER SKILLS*/
.otherSkill {
  padding: 20px;
}

.otherSkill h3 {
  font-size: 1.2em;
}

.extra-skill-cont {
  width: 100%;
  padding: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  grid-column-gap: 120px;
  -webkit-column-gap: 120px;
          column-gap: 120px;
}

.extra-skill-box {
  max-width: 350px;
  padding: 5px 10px;
}

.range-div {
  background: #021811;
  display: flex;
  justify-content: center;
  align-items: center;
}

.range {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.281);
  position: relative;
}

.skill-rg {
  height: 100%;
  width: 80%;
  background: linear-gradient(to right, #8b0a26, #e60b09);

  background: -webkit-linear-gradient(to right, #dc184f, #e60b09);

  border-radius: 10px;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* .skill-rg.bar-comp {
  width: 90%;
}
.skill-rg.bar-ps {
  width: 85%;
}
.skill-rg.bar-tw {
  width: 80%;
}
.skill-rg.bar-comm {
  width: 80%;
}
.skill-rg.bar-ql {
  width: 85%;
}
.skill-rg.bar-wp {
  width: 70%;
} */

/****************** Project ***************/
.projects {
  padding: 20px;
  display: grid;
  grid-gap: 15px;
  justify-items: center;
}

.proj-content {
  padding: 10px 20px;
  display: grid;
  grid-gap: 10px;
  border-bottom: 1px solid rgb(140, 165, 172);
  transition: 0.5s ease-in-out;
}

.proj-main {
  display: grid;
  grid-row-gap: 15px;
  row-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  align-content: center;
}

.proj-img-div {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.proj-link-div {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 5px 20px 2px rgba(11,68,82,1);
  position: relative;
}

.proj-link-div img {
  width: 100%;
  object-fit: cover;
}

.proj-link-div a {
  /* position: absolute; */
  left: 0;
  bottom: -28px;
  width: 100%;
  text-align: center;
  color: rgb(255, 255, 255);
  /* background-color: rgba(43, 156, 141, 0.719); */
  background-color: rgb(3, 59, 66);
  transition: 0.2s ease-in-out;
}

.proj-link-div a:hover,
.proj-link-div a:active {
  text-shadow: 0px 0px 10px #da1e37,
               0px 0px 20px #da1e37,
               0px 0px 30px #da1e37,
               0px 0px 40px #da1e37,
               0px 0px 50px #da1e37;
  text-shadow: 0px 0px 10px var(--classyRed),
               0px 0px 20px var(--classyRed),
               0px 0px 30px var(--classyRed),
               0px 0px 40px var(--classyRed),
               0px 0px 50px var(--classyRed);
}

.proj-desc {
  width: 80%;
  position: relative;
}

.proj-desc h2 {
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(145, 163, 168);
}

.proj-icon-link {
  position: absolute;
  background: #da1e37;
  background: var(--classyRed);
  border-radius: 50%;
  top: 7px;
  left: -35px;
  width: 27px;
  height: 27px;
  background-image: url(/static/media/opinionImg.7c26ff49.JPG);
  background-size: cover;
  box-shadow: 0 0 2px 1px #da1e37;
  box-shadow: 0 0 2px 1px var(--classyRed);
}

.proj-icon-link:active,
.proj-icon-link:hover {
  opacity: 0.8;
}

.opinion-icon {
  background-image: url(/static/media/opinionImg.7c26ff49.JPG);
}
.clock-icon {
  background-image: url(/static/media/clock-weather.56b3fe73.PNG);
}
.bg-icon {
  background-image: url(/static/media/bg_proj_img.52be1022.JPG);
}
.simon-icon {
  background-image: url(/static/media/simon_game1.5c562b2d.PNG);
}
.addAPI-icon {
  background-image: url(/static/media/addressAPI.80c49886.PNG);
}

.language-icon {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
}
.language-icon div {
  align-items: center;
}

.language-icon img {
  cursor: pointer;
  width: min(30px, 100%);
  padding: 2px;
  margin: 3px;
  border-radius: 10px;
  box-shadow: 0 0 2px 2px rgba(6, 51, 68, 0.425);
  transition: 0.5s ease;
}
.language-icon img:focus,
.language-icon img:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  box-shadow: 0 0 2px 2px rgba(6, 51, 68, 0.425),
    0 5px 0 0 rgba(6, 52, 68, 0.212);
}

.proj-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding: 2%;
}

.github-link {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.github-link img {
  max-height: 40px;
  padding: 5px;
  border-radius: 10px;
  transition: 0.2s ease-in;
  box-shadow: 0 0 2px 2px rgba(6, 51, 68, 0.425);
}

.proj-diagram {
  color: black;
  text-align: center;
  line-height: 1.4rem;
  width: min(80px, 100%);
}

.proj-diagram button {
  cursor: pointer;
  padding: 3px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(6, 51, 68, 0.425);
}

.github-link img:hover,
.github-link img:active,
.proj-diagram button:hover,
.proj-diagram button:active {
  box-shadow: 0 0 5px 1px #da1e37;
  box-shadow: 0 0 5px 1px var(--classyRed);
}

.about-proj-div {
  padding-bottom: 10px;
  transition: 0.5s ease-in-out;
  display: grid;
  grid-gap: 5px;
}

.link-div {
  /* display: flex;
  justify-content: center; */
  margin-left: 2rem;
}

.about-proj-link {
  color: #06a575;
  color: var(--classyGreen);
  text-decoration: underline;
  text-align: center;
}

.about-proj-link:active,
.about-proj-link:hover {
  color: #da1e37;
  color: var(--classyRed);
}

.about-proj {
  min-width: 320px;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.281);
  padding: 10px;
  border-radius: 5px;
  overflow: hidden;
  transition: 0.5s ease-in-out;
}

@-webkit-keyframes smoothRoll {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@keyframes smoothRoll {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@-webkit-keyframes smoothRollBack {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}

@keyframes smoothRollBack {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}

.about-proj-show {
  display: block;
  -webkit-animation-name: smoothRoll;
          animation-name: smoothRoll;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.about-proj-rollBack {
  -webkit-animation-name: smoothRollBack;
          animation-name: smoothRollBack;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.about-proj-hide {
  display: none;
}

/************ Contact / Footer ******************/

.contact-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.div-cont {
  width: 100%;
  max-width: 1100px;
  /* min-height: 70vh; */
  padding: 2% 5%;
  margin-bottom: 5px;
  color: #ffffff;
  color: var(--mainColor);
  display: grid;
  /* grid-gap: 10px; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  justify-items: center;
  align-items: center;
}
.div-cont > :first-child {
  border-right: 1px solid black;
}

.content {
  width: min(100%, 500px);
  height: min(450px, 100%);
  padding: 0 15px 10px 15px;
  font-family: poppins, "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-size: 0.8em;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 35px 1fr;
  grid-gap: 5px;
  letter-spacing: 1px;
}

.content h1,
.content h4,
.content p {
  text-align: center;
}

#form {
  /* display: flex; */
  flex-direction: column;
  position: relative;
}

.form-head h4 {
  font-weight: 600;
  font-size: 1.5rem;
}

#emailSentMsg {
  color: rgb(55, 231, 55);
  line-height: 2rem;
}

/* email sending loader */
.loader {
  background-color: #82cbd800;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: #da1e37;
  background: var(--classyRed);
  -webkit-animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
          animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: #da1e37;
  background: var(--classyRed);
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@-webkit-keyframes lds-heart {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  39% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}
@keyframes lds-heart {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  5% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  39% {
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  60% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
}

/* loader end  */

#email-form {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  padding: 0 10px 10px;
}

.emailField {
  display: flex;
  align-items: center;
  background-color: rgba(9, 194, 207, 0.082);
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px 10px;
  color: white;
  position: relative;
}

.emailField > label {
  display: none;
  content: 'Name';
  position: absolute;
  top: -3px;
  background: #2c5364;
  color: white;
  line-height: 0.5;
  padding: 0 2px;
  text-align: center;
}

.emailField > .gotValue {
  color: #06a575;
  color: var(--classyGreen);
}

#email-form input,
#email-form textarea {
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0);
  color: white;
  font-size: 1.7rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 5px;
  border: none;
  padding: 5px 0;
}

input:-internal-autofill-selected,
textarea:-webkit-autofill {
  background-color: rgba(255, 255, 255, 0) !important;
}

#email-form > .gotFocus {
  border: 2px solid #06a575;
  border: 2px solid var(--classyGreen);
}

#email-form input::-webkit-input-placeholder, #email-form textarea::-webkit-input-placeholder {
  color: rgba(245, 245, 245, 0.658);
}

#email-form input:-ms-input-placeholder, #email-form textarea:-ms-input-placeholder {
  color: rgba(245, 245, 245, 0.658);
}

#email-form input::placeholder,
#email-form textarea::placeholder {
  color: rgba(245, 245, 245, 0.658);
}

#email-form textarea {
  resize: none;
  padding: 3% 0;
}

#email-form input:focus,
#email-form textarea:focus{
  outline: none;
  /* border: 2px solid var(--classyGreen); */
}

.btn-submit-div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}

.btn-submit {
  width: 100%;
  height: 4rem;
  border-radius: 12px;
  cursor: pointer;
  background: #da1e37;
  background: var(--classyRed);
  -webkit-background: #da1e37;
  -webkit-background: var(--classyRed);
  color: white;
  font-family: sans-serif;
  font-weight: bold;
  transition: 0.5s ease;
  text-transform: uppercase;
  outline: none;
  border: none;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.btn-submit::after {
  content: "";
  position: absolute;
  width: 10%;
  height: 100px;
  -webkit-transform: rotate(35deg);
          transform: rotate(35deg);
  background: rgba(204, 200, 200, 0.377);
  top: -50%;
  left: -25%;
  transition: 0.5s ease-in-out;
  box-shadow: 0 0 10px 15px rgba(204, 197, 197, 0.315);
  z-index: -1;
}

.btn-submit::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #da1e37;
  background: var(--classyRed);
  top: 0;
  left: -100%;
  border-radius: 12px;
  transition: 0.5s ease-in-out;
  text-align: center;
  font-weight: bold;
  z-index: -1;
}

.btn-submit:hover::after {
  left: 110%;
}

/*Contacts*/

.cont-link {
  min-height: 190px;
  grid-row: 1 / span 2;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: flex-start;
}

.cont-link > :first-child > h1 {
  margin-bottom: 5px;
}

.get-in-touch {
  grid-template-columns: 1fr;
  justify-items: flex-start;
}

.get-in-touch div {
  padding-left: 50px;
  display: grid;
  grid-template-columns: 20px 1fr;
  grid-gap: 10px;
}

#privacy {
  grid-row: 3;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/*************** Media queries *****************/

@media only screen and (max-width: 655px) {
  #intro .intro-div-box {
    height: 100vh;
  }

  .intro-div-box .content-wrapper {
    padding: 0;
  }

  header {
    position: fixed;
    top: 0;
    background: none;
    box-shadow: none;
  }

  .prof_img {
    width: 60px;
  }

  #header .lines {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #header .line-height {
    height: 70%;
  }

  #header nav {
    position: absolute;
    right: 0;
    width: 25%;
    height: 15em;
    top: 4em;
    border-radius: 7px 0 0 7px;
    display: flex;
    flex-direction: column;
    background-color: rgba(153, 228, 238, 0.274);
    box-shadow: none;
    box-shadow: 0 0px 10px 0px rgba(13, 13, 14, 0.274);
    backdrop-filter: blur(1rem);
    -moz-backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -moz-transform: translateX(100%);
    -o-transform: translateX(100%);
    transition: 0.5s ease;
  }

  #header nav .nav-bar {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav li {
    width: 100%;
    text-align: center;
    background-color: rgba(224, 230, 233, 0.356);
    box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.342);
  }

  .nav-bar {
    padding: 0;
  }

  nav li:hover,
  nav li:active {
    background: rgb(190, 202, 211);
  }

  .nav-bar li a {
    font-size: 14px;
  }

  .nav-bar li a:hover,
  .nav-bar li a:active {
    text-shadow: none;
  }

  #header .nav-active {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }

  .div-box,
  .about-content .div-box,
  .skill-content .div-box {
    margin: 0;
  }

  .div-cont > :first-child {
    border: none;
    border-bottom: 1px solid black;
  }

  #contact {
    height: 100vh;
    background-image: -webkit-linear-gradient(90deg, #0f2027, #203a43, #2c5364);
    background-image: var(--gradientSmall);
  }

  .div-cont {
    height: 100vh;
  }
}

@media only screen and (max-width: 480px) {
  body {
    overflow-x: hidden;
    font-size: 1.5rem;
  }

  .illus-watch {
    display: none;
  }

  .title-wrap {
    grid-template-columns: 1fr;
    width: 100%;
    height: 80%;
  }

  .desc {
    align-items: center;
    margin-left: 5%;
    width: 100%;
  }
  .contain-wrap {
    width: 100%;
  }

  .main_img {
    order: 1;
    flex: 1 1;
    height: 65%;
  }

  .div-box {
    width: 98%;
    margin-top: 1em !important;
    overflow: visible;
  }
  .div-box:before {
    -webkit-transform: none;
            transform: none;
    top: -15px;
    left: 0;
    border-radius: 1rem 0 0 0;
    text-decoration: none;
    font-size: 18px;
  }

  .content-wrapper {
    display: block;
    padding-top: 2rem;
  }

  .projects {
    padding-top: 2.5rem;
  }

  .otherSkill, .proj-content {
    padding: 0;
  }
  .otherSkill {
    padding-top: 12px;
  }

  .extra-skill-cont {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }

  #contact {
    height: 100vh;
  }

  #contact .div-cont {
    display: grid;
    height: 100vh;
  }

  .content {
    padding: 2% 2%;
  }

  #form {
    flex: 5 1;
  }

  .cont-link .soc_media a img {
    width: 25px;
    height: 25px;
  }

  .cont_text {
    font-size: 14px;
  }

  #privacy {
    font-size: 13px;
  }
}

